
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { BIconStarFill, BIconPerson } from "bootstrap-vue";
import { MainMenu } from "~/types/components";

@Component({
  components: {
    BIconStarFill,
    BIconPerson,
  },
})
export default class TheMenu extends Vue {
  @Prop() mainMenu!: MainMenu[];

  public clickOnMyAsta(e: Event) {
    if (!this.$auth.loggedIn) {
      e.stopPropagation();
      this.$router.push("/myAsta/login");
    }
  }
}
